<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.outsource_add") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitPatient(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-main>
      <el-row :gutter="24">
        <!-- Поиск -->
        <el-col :span="10">
          <form
            class="mt-2 width-100 d-flex flex-wrap"
            v-on:submit.prevent="findOrder"
          >
            <el-input
              :placeholder="$t('message.enter_id')"
              v-model="id"
              :disabled="search_input"
              class="input-with-select"
            >
              <el-button
                @click="findOrder()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </form>
        </el-col>

        <!-- Выбор услуги -->
        <el-col :span="10" v-if="order_show">
          <el-select
              class="mt-2 width-100 d-flex flex-wrap"
              v-model="order_service_id"
              filterable
              clearable
              @change="updateSelectedService"
          >
            <el-option
                v-for="service in tableServicesData"
                :key="service.id"
                :label="service.name"
                :value="service.id"
            ></el-option>
          </el-select>
        </el-col>

        <!-- Таблица пациента -->
        <el-col v-if="order_show" :span="24" class="mt-2">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span> {{ $t("message.patient") }}</span>
            </div>
            <div>
              <el-table :data="tablePatientData" style="width: 100%">
                <el-table-column label="№" width="110">
                  <template slot-scope="scope"> # {{ scope.row.id }} </template>
                </el-table-column>
                <el-table-column
                  prop="first_name"
                  :label="$t('message.first_name')"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="surname" :label="$t('message.surname')">
                </el-table-column>
                <el-table-column
                  prop="patronymic"
                  :label="$t('message.patronymic')"
                >
                </el-table-column>
                <el-table-column prop="born_date" :label="$t('message.dob')">
                </el-table-column>
                <el-table-column prop="gender" :label="$t('message.gender')">
                </el-table-column>
                <el-table-column prop="code" :label="$t('message.code')">
                </el-table-column>
                <el-table-column prop="phone" :label="$t('message.phone')">
                </el-table-column>
                <el-table-column :label="$t('message.social')">
                  <template slot-scope="scope">
                    {{ scope.row.social ? scope.row.social.name : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" :label="$t('message.date')">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>

        <!-- Таблица заказать услугу -->
        <el-col v-if="order_show" :span="24" class="mt-2">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ $t("message.order_service") }}</span>
            </div>
            <div>
              <el-table :data="[selectedService]" style="width: 100%" v-if="selectedService">
                <el-table-column prop="name" :label="$t('message.service_name')" width="280"></el-table-column>
                <el-table-column prop="price" :label="$t('message.price2')">
                  <template slot-scope="scope">
                    {{ scope.row.price | formatMoney }}
                  </template>
                </el-table-column>
                <el-table-column prop="discount_price" :label="$t('message.discount')"></el-table-column>
                <el-table-column prop="code" :label="$t('message.code')"></el-table-column>
                <el-table-column prop="lifetime" label="Срок услуги"></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>

    <div class="grid-content" v-if="order_show">
      <div class="add_patient">
        <el-form ref="formPatient" :model="form" :rules="rules" class="twoform">
          <el-row :gutter="24">
            <el-col :span="12">
              <!-- Загруженный файл -->
              <div v-if="form.fileList && form.fileList.length > 0" class="uploaded-file">
                <div class="file-preview">
                  <i class="el-icon-document"></i>
                  <span class="file-name">{{ form.fileList[0].name }}</span>
                  <el-button
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      @click="removeExistingFile"
                      class="delete-button"
                  ></el-button>
                </div>
              </div>

              <!-- Загрузка новых файлов -->
              <el-upload
                  v-else
                  class="upload-demo"
                  drag
                  :action="uploadAction"
                  :headers="uploadHeaders"
                  :data="uploadData"
                  :file-list="form.fileList"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                  :limit="1"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  <em>{{ $t("message.file_upload") }}</em>
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [drawerChild],
  props: ["outsource_id"],
  data() {
    return {
      loadingButton: false,
      loading: false,
      order_show: false,
      outsource: null,
      search_input: false,
      tablePatientData: [],
      tableServicesData: [],
      order_service_id: this.selectedServiceData ? this.selectedServiceData.id : null,
      selectedService: this.selectedServiceData,
      selectedServiceData: null,
      id: "",
      form: {
        fileList: [],
        outsourcing_file_id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      rules: "outsource/rules",
      model: "orders/relation_model",
    }),
    uploadAction() {
      return this.base_url + 'outsourcing/storeFile';
    },
    uploadHeaders() {
      return {
        Authorization: 'Bearer ' + this.$store.getters.token,
      };
    },
    uploadData() {
      return {
        order_id: this.id,
        service_id: this.selectedServiceData.id,
      };
    },
  },
  methods: {
    ...mapActions({
      save: "outsource/update",
      showOutsource: "outsource/show",
      showModel: "orders/relationShow",
    }),
    afterClosed() {},
    afterOpened() {
      this.loadModel();
      this.findOutsource();
      this.order_show = true;
      this.id = this.model.id;
      this.search_input = true;
    },
    handleRemove(file, fileList) {
      this.form.fileList = fileList;
      this.form.outsourcing_file_id = "";
    },
    handleSuccess(response, file, fileList) {
      this.form.fileList = fileList;
      if (response.data && response.data.file) {
        this.form.outsourcing_file_id = response.data.file.id;
      }
    },
    removeExistingFile() {
      this.form.fileList = [];
      this.form.outsourcing_file_id = "";
    },
    loadModel() {
      this.tablePatientData = [JSON.parse(JSON.stringify(this.model.patient))];
      this.tableOrderData = [JSON.parse(JSON.stringify(this.model))];
      const orderServicesData = JSON.parse(
        JSON.stringify(this.model.order_services)
      );
      orderServicesData.forEach(service => {
        if (service.for_outsource)
          this.tableServicesData.push(JSON.parse(JSON.stringify(service)));
      })
    },
    findOrder() {
      this.loading = true;
      const query = { id: this.id, relation: true, free_orders: true };
      this.showModel(query)
        .then((res) => {
          this.order_show = true;
          this.loading = false;
          this.loadModel();
        })
        .catch((err) => {
          this.loading = false;
          this.order_show = false;
          this.$notify({
            title: "Ошибка ",
            type: "error",
            offset: 130,
            dangerouslyUseHTMLString: true,
            message: "Заказ не найден",
          });
        });
    },
    findOutsource() {
      this.loading = true;
      this.showOutsource(this.outsource_id)
        .then((res) => {
          this.outsource = res.data.outsourcing;
          this.order_service_id = this.outsource.order_service_id;
          this.selectedServiceData = this.tableServicesData.find((service) => service.order_service_id === this.outsource.order_service_id);
          if (this.outsource.file !== null) {
            this.form.fileList = [
              {
                name: this.outsource.file.file.split('/').pop(), // Извлекаем имя файла из пути
                url: this.outsource.file.file,
                id: this.outsource.file.id, // Добавляем id файла
              },
            ];
            this.form.outsourcing_file_id = this.outsource.file.id;
          } else {
            this.form.fileList = [];
          }
        })
        .catch({});
    },
    resetForm(formName) {
      if (this.$refs[formName]) this.$refs[formName].resetFields();

      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
    submitPatient(close = true) {
      this.$refs["formPatient"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          const order_service = this.tableOrderData[0].order_services.find((order_service) => order_service.id === this.order_service_id);
          this.form.order_service_id = order_service.order_service_id;
          // this.form.fileList.old_file_id = this.outsource.file.id;
          const query = { id: this.outsource_id, data: this.form };

          this.save(query)
            .then((res) => {
              this.$alert(res.message);
              this.form = {};
              this.parent().listChanged();
              this.loadingButton = false;
              this.close();
              this.$emit("c-close", {
                reload: true,
                drawer: "drawerCreatePatient",
              });
              this.$refs["formPatient"].resetFields();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    updateSelectedService(value) {
      this.selectedService = this.tableServicesData.find(service => service.id === value);
    },
  },
  watch: {
    selectedServiceData: {
      handler(newVal) {
        this.order_service_id = newVal ? newVal.id : null;
        this.selectedService = newVal;
      },
      immediate: true,
      deep: true,
    },
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.loadOrder();
        }
      },
      deep: true,
      immediate: true,
    },
    outsourcing: {
      handler(newVal) {
        if (newVal && newVal.file) {
          this.form.fileList = [
            {
              name: newVal.file.name,
              url: newVal.file.url,
              id: newVal.file.id,
            },
          ];
          this.form.outsourcing_file_id = newVal.file.id;
        } else {
          this.form.fileList = [];
          this.form.outsourcing_file_id = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.el-table .cell {
  word-break: break-word;
  line-height: 140%;
  text-align: center;
}
.uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  background-color: #f5f7fa;
  position: relative;
}
.file-preview {
  display: flex;
  align-items: center;
}
.file-name {
  margin-left: 10px;
  font-size: 14px;
  color: #606266;
}
.delete-button {
  position: absolute;
  right: 10px;
}
</style>